<template>
  <ListComponent title="Legs" :totalCount="totalLegs">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="pl-0 pr-2 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search On Enter"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            @click:clear="onEnter()"
            hide-details="auto"
            v-model="filters.search"
            v-on:keyup.enter="onEnter"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFiltersDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(nonEmptyFilters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <!-- Text when no filters are applied -->
            <span v-if="Object.keys(nonEmptyFilters).length === 0"
              >Filters</span
            >

            <!-- Show applied filters if any -->
            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in nonEmptyFilters"
                  :key="key"
                  class="text-capitalize"
                >
                  {{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>
        <v-col cols="4" class="text-center">
          <span class="text-h6 font-weight-regular">
            Total Selected Leg's -
            <span class="font-weight-bold"> {{ movementDateCount }} </span>
          </span>
        </v-col>
        <v-col cols="4" class="py-0 d-flex justify-end">
          <div class="d-flex align-center">
            <v-btn
              @click="previousDate('start')"
              icon
              small
              depressed
              color="primary"
            >
              <v-icon> mdi-arrow-left </v-icon>
            </v-btn>
            <DateField
              ref="startDateField"
              :inputProps="{
                dense: true,
                label: 'Start Date',
                'hide-details': 'auto',
              }"
              v-model="filters.start_date"
              @input="
                itemsPerPageChanged(100);
                setDate('start');
              "
            />
            <v-btn
              @click="nextDate('start')"
              icon
              small
              depressed
              color="primary"
            >
              <v-icon> mdi-arrow-right </v-icon>
            </v-btn>
          </div>
          <div class="d-flex align-center">
            <v-btn
              @click="previousDate('end')"
              icon
              small
              depressed
              color="primary"
            >
              <v-icon> mdi-arrow-left </v-icon>
            </v-btn>
            <DateField
              ref="endDateField"
              :inputProps="{
                dense: true,
                label: 'End Date',
                'hide-details': 'auto',
              }"
              v-model="filters.end_date"
              @input="
                itemsPerPageChanged(100);
                setDate('end');
              "
            />
            <v-btn
              @click="nextDate('end')"
              icon
              small
              depressed
              color="primary"
            >
              <v-icon> mdi-arrow-right </v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 232px)"
            class="small-table ag-theme-alpine"
            id="legsGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :isRowSelectable="isRowSelectable"
            rowSelection="multiple"
            @selection-changed="onSelectionChanged"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allLegList"
          ></AgGridVue>
        </v-col>
        <v-col v-if="totalLegs > 0" cols="12" class="py-0 d-flex justify-end">
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalLegs"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>
      </v-row>
    </template>

    <template #dialogs>
      <AdminTripSchedulingFilters
        :bindingObject="filters"
        v-model="showFiltersDialog"
        @resetFilters="resetFilters()"
        @applyFilters="itemsPerPageChanged(20)"
      />
      <CombineLegForm
        v-model="showCombineLegForm"
        @refreshList="getLegsList()"
        :legId="selectedLegId"
        :companyId="transporterCompanyId"
      />
      <AdminTripForm
        v-model="showAdminTripForm"
        :legId="selectedLegId"
        @refreshList="getLegsList()"
        :transporterCompanyId="transporterCompanyId"
        @closeTripForm="closeTripForm"
      />
      <AdminLegExportDialog
        v-model="showLegExportDialog"
        @refreshList="getLegsList()"
      />
      <BulkOperationsDialog
        v-model="showBulkOperationsDialog"
        :legs="selectedLags"
        @refreshList="getLegsList()"
      />
      <SubhireForm
        v-model="showSubhireDialog"
        :legNumber="selectedRowData.leg_id"
        :daNumber="selectedRowData.da_no"
        :subHireNumber="selectedRowData.sub_hire_no"
        :legId="selectedRowData.id"
        :subHireID="selectedRowData.leg_sub_hire"
        :customerId="selectedRowData.customer_name"
        :daContractRate="selectedRowData.customer_rate"
        :jobStatus="selectedRowData.sub_hire_status"
        :containerType="selectedRowData.leg_container_details"
        @refreshList="getLegsList()"
      />
      <AdminAttachmentsDownload v-model="showAttachmentsDialog" />
      <MarkDAReadyForInvoice
        v-model="showMarkDAReadyForInvoiceForm"
        @refreshList="getLegsList()"
      />
      <CustomTextFieldDialog
        v-model="showDARemarksDialog"
        :fieldValue="selectedDARemarks"
        :dialogTitle="daRemarksDialog"
        :objID="selectedDAID"
        @refreshList="getLegsList()"
      />
      <ManualSequenceDialog
        v-model="showManualSequenceDialog"
        :fieldValue="selectedManualSequence"
        :dialogTitle="legManualSequenceDialogTitle"
        :objID="selectedLegID"
        @refreshList="getLegsList()"
      />
      <LegETADialog
        v-model="showlegETADialog"
        :fieldValue="selectedLegETA"
        :dialogTitle="legETADialogTitle"
        :objID="selectedLegID"
        @refreshList="getLegsList()"
      />
      <LegContainerSealNoDialog
        v-model="showlegContainerSealNoDialog"
        :dialogTitle="legContainerSealNoDialogTitle"
        :objID="selectedLegID"
        @refreshList="getLegsList()"
      />
      <DAHazardousForm
        v-model="showHazardousDialog"
        :dialogTitle="daHazardousFormDialog"
        :hazardousId="selectedHazardousId"
        :objID="selectedDAID"
        @refreshList="getLegsList()"
      />
      <LegMovementDateForm
        v-model="showMovementDateDialog"
        :legs="selectedLags"
        module="legs"
        @refreshList="
          getLegsList();
          selectedLags = [];
        "
      />
    </template>

    <template #actions>
      <v-row class="ma-0">
        <v-col class="pa-0 d-flex align-center">
          <v-sheet class="pl-2 rounded-lg" elevation="0" outlined>
            <template>
              <v-chip-group
                v-model="filters.statuses"
                multiple
                selected-class="font-weight-medium"
                @change="
                  setFilters();
                  getLegsList();
                "
              >
                <v-chip
                  v-for="(tripStatus, tripStatusIndex) in statusList"
                  :key="tripStatusIndex"
                  label
                  small
                  filter
                  :value="tripStatus.value"
                  filter-icon="mdi-check"
                  :color="getColorWithLighten(tripStatus.value)"
                  :text-color="getColorWithDarken(tripStatus.value)"
                  class="font-weight-bold"
                >
                  {{ tripStatus.value }}
                </v-chip>
              </v-chip-group>
            </template>
          </v-sheet>
        </v-col>

        <v-col cols="auto" class="d-flex align-center Form-Columns">
          <template>
            <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Bulk Actions
                  </v-btn>
                </template>

                <v-list class="rounded-lg">
                  <v-list-item-group class="pa-0">
                    <v-list-item
                      :disabled="!(permissions && permissions.add)"
                      class="cursor-pointer text-left ma-0 pa-0 container rounded-lg px-3"
                      @click="showMarkDAReadyForInvoiceForm = true"
                    >
                      <v-icon color="primary" size="medium" class="pr-3">
                        mdi-invoice-check
                      </v-icon>
                      <span class="text-subtitle">Ready For Invoice</span>
                    </v-list-item>

                    <v-list-item
                      class="cursor-pointer text-left container ma-0 pa-0 rounded-lg px-3"
                      @click="showAttachmentsDialog = true"
                    >
                      <v-icon color="primary" size="medium" class="pr-3">
                        mdi-file-download
                      </v-icon>
                      <span class="text-subtitle">Download PODs</span>
                    </v-list-item>
                    <v-list-item
                      class="cursor-pointer text-left ma-0 pa-0 container rounded-lg px-3"
                      @click="showLegExportDialog = true"
                    >
                      <v-icon color="primary" size="medium" class="pr-3">
                        mdi-text-search
                      </v-icon>
                      <span class="text-subtitle">Advance Search</span>
                    </v-list-item>
                    <v-list-item
                      :disabled="
                        !(currentProfile && currentProfile.is_primary_user)
                      "
                      class="cursor-pointer text-left ma-0 pa-0 container rounded-lg px-3"
                      @click="showBulkOperationsDialog = true"
                    >
                      <v-icon color="primary" size="medium" class="pr-3">
                        mdi-update
                      </v-icon>
                      <span class="text-subtitle">Update Bonus</span>
                    </v-list-item>

                    <v-list-item
                      :disabled="
                        !(selectedLags && selectedLags.length && isSubHireId)
                      "
                      class="cursor-pointer text-left ma-0 pa-0 container rounded-lg px-3"
                      @click="completeLegs()"
                    >
                      <v-icon color="primary" size="medium" class="pr-3">
                        mdi-truck-check
                      </v-icon>
                      <span class="text-subtitle">Complete Sub Hire Legs</span>
                    </v-list-item>

                    <v-list-item
                      :disabled="!(selectedLags && selectedLags.length)"
                      class="cursor-pointer text-left ma-0 pa-0 container rounded-lg px-3"
                      @click="showMovementDateDialog = true"
                    >
                      <v-icon color="primary" size="medium" class="pr-3"
                        >mdi-calendar-edit</v-icon
                      >
                      <span class="text-subtitle"> Change Date </span>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-col>
      </v-row>
    </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import Columns from "@/components/General/Columns.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminTripSchedulingButton from "@/components/AgGridComponents/AdminTripSchedulingButton.vue";
import CombineLegForm from "@/components/ModuleBased/dialogForms/CombineLegForm.vue";
import AdminTripForm from "@/components/ModuleBased/dialogForms/AdminTripForm.vue";
import MarkDAReadyForInvoice from "@/components/ModuleBased/dialogForms/MarkDAReadyForInvoice.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import AdminLegExportDialog from "@/components/ModuleBased/dialogForms/AdminLegExportDialog.vue";
import BulkOperationsDialog from "@/components/ModuleBased/dialogForms/BulkOperationsDialog.vue";
import SubhireForm from "@/components/ModuleBased/dialogForms/SubhireForm.vue";
import LegMovementDateForm from "@/components/ModuleBased/dialogForms/LegMovementDateForm.vue";
import LegContainerSealNoDialog from "@/components/ModuleBased/dialogForms/LegContainerSealNoDialog.vue";
import DAHazardousForm from "@/components/ModuleBased/dialogForms/DAHazardousForm.vue";
import ManualSequenceDialog from "@/components/ModuleBased/dialogForms/ManualSequenceDialog.vue";
import LegETADialog from "@/components/ModuleBased/dialogForms/LegETADialog.vue";
import CustomTextFieldDialog from "@/components/ModuleBased/dialogForms/CustomTextFieldDialog.vue";
import AdminAttachmentsDownload from "@/components/ModuleBased/dialogForms/AdminAttachmentsDownload.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import AdminTripSchedulingFilters from "@/components/ModuleBased/dialogs/filters/AdminTripSchedulingFilters.vue";
import StatusChip from "@/components/AgGridComponents/General/StatusChip.vue";
import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
  getColorWithLighten,
  getColorWithDarken,
} from "@/utils/functions";
import {
  LAST_YEAR,
  LAST_MONTH,
  LAST_WEEK,
  TODAY,
  THIS_WEEK,
  THIS_MONTH,
  THIS_YEAR,
} from "@/utils/dateRangePicker";
export default {
  components: {
    LegMovementDateForm,
    ListComponent,
    Columns,
    AgGridVue,
    Pagination,
    agColumnHeader: CustomHeader,
    AdminTripSchedulingButton,
    CustomTextFieldDialog,
    CombineLegForm,
    DAHazardousForm,
    AdminTripForm,
    LegETADialog,
    InputField,
    MultiSelect,
    BulkOperationsDialog,
    AdminLegExportDialog,
    AdminAttachmentsDownload,
    LegContainerSealNoDialog,
    DateField,
    ManualSequenceDialog,
    MarkDAReadyForInvoice,
    SubhireForm,
    AdminTripSchedulingFilters,
    StatusChip,
  },
  data() {
    return {
      getListCalled: false,
      showFiltersDialog: false,
      sorting: {},
      transporterCompanyId: null,
      all: true,
      includedPresetDates: [
        LAST_YEAR,
        LAST_MONTH,
        LAST_WEEK,
        TODAY,
        THIS_WEEK,
        THIS_MONTH,
        THIS_YEAR,
      ],

      showlegContainerSealNoDialog: false,
      selectedLegContainers: null,
      legContainerSealNoDialogTitle: null,
      legListTimeout: null,
      movementDateCount: 0,

      showlegETADialog: false,
      selectedLegETA: null,
      legETADialogTitle: null,

      showManualSequenceDialog: false,
      selectedManualSequence: null,
      legManualSequenceDialogTitle: null,

      selectedLegID: null,

      showHazardousDialog: false,
      selectedHazardousId: null,
      daHazardousFormDialog: null,

      showDARemarksDialog: false,
      selectedDAID: null,
      selectedDARemarks: null,
      daRemarksDialog: null,

      showLegExportDialog: false,
      showBulkOperationsDialog: false,
      showAttachmentsDialog: false,
      showMovementDateDialog: false,
      showSubhireDialog: false,

      tabs: null,
      showAdminTripForm: false,
      showMarkDAReadyForInvoiceForm: false,
      allLegList: [],

      showCombineLegForm: false,
      selectedLegId: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 100,
      totalLegs: 100,
      containerCentric: "No",

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        rowStyle: {
          "font-size": "10px",
        },
        onColumnMoved: () => {
          this.$bus.$emit("syncPreferences");
        },
        rowHeight: 30,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },

      // Filter vars
      filters: {
        statuses: [],
      },
      getColorWithLighten,
      getColorWithDarken,
      permissions: null,
      daPermissions: null,
      isSubHireId: false,
      searchTimeout: null,
      selectedLags: [],
      selectedRowData: [],
      statusList: [
        {
          text: "Created",
          value: "Created",
          color: "white",
        },
        {
          text: "Assigned",
          value: "Assigned",
          color: "yellow",
        },
        {
          text: "Enroute",
          value: "Enroute",
          color: "secondary",
        },

        {
          text: "Reached Drop Location",
          value: "Reached Drop Location",
          color: "orange",
        },
        {
          text: "Completed",
          value: "Completed",
          color: "light-blue",
        },
        {
          text: "Ready For Invoice",
          value: "Ready For Invoice",
          color: "green",
        },
        {
          text: "Invoice Generated",
          value: "Invoice Generated",
          color: "deep-purple",
        },
      ],
      headers: [
        {
          headerName: "Depot",
          field: "depot",
          pinned: true,
          tooltipField: "depot",
          minWidth: 45,
          sortable: true,
        },
        {
          headerName: "DA",
          field: "da_no",
          pinned: true,
          sortable: true,
          minWidth: 65,
          tooltipField: "da_status",
          cellClass: ["primary--text font-weight-bold cursor-pointer"],

          cellStyle: (params) => {
            let style = {
              unSortIcon: true,
            };

            return style;
          },

          onCellClicked: (params) => {
            if (this.daPermissions && this.daPermissions.view) {
              if (params && params.data && params.data.da_id) {
                const path = {
                  path: `/app/admin/delivery-advice/details/${params.data.da_id}`,
                  query: {
                    backRoute: "/app/admin/trip/list",
                  },
                };
                this.$router.push(path);
              }
            }
          },
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          minWidth: 150,
          pinned: true,
          tooltipField: "status",
          cellRenderer: "StatusChip",
          cellRendererParams: (params) => {
            return {
              status: params.value,
            };
          },

          // cellClass: ["font-weight-medium"],
          // cellStyle: (params) => {
          //   return { backgroundColor: params.data.status_color };
          // },
        },
        {
          headerName: "Invoice",
          field: "customer_invoice",
          sortable: true,
          minWidth: 80,
          tooltipField: "customer_invoice",
          cellClass: ["font-weight-bold"],
        },
        {
          headerName: "Customer",
          field: "customer_name",
          sortable: true,
          minWidth: 65,
          tooltipField: "customer_name",
        },

        {
          headerName: "Haz",
          field: "is_hazmat",
          minWidth: 50,
          sortable: true,
          onCellClicked: (params) => {
            if (this.containerCentric === "No" && params.value == "Yes") {
              this.showHazardousDialog = true;
              this.selectedDAID = params.data.da_id;
              this.selectedHazardousId = params.data.da_hazardous_cargo_id;
              this.daHazardousFormDialog = `Hazardous Cargo Details - ${params.data.da_no}`;
            }
          },
          cellRenderer: function (params) {
            if (params.value == "Yes") {
              return `<span class="">
                <i class="mdi mdi-alert red--text">${params.value}</i>
                <i class="mdi mdi-pencil primary--text"></i>
              </span>`;
            } else {
              return params.value;
            }
          },
          tooltipField: "is_hazmat",
        },
        {
          headerName: "Plan/Sequence",
          field: "manual_sequence",
          sortable: true,
          minWidth: 30,
          tooltipField: "manual_sequence",
          onCellClicked: (params) => {
            if (this.containerCentric === "No") {
              this.showManualSequenceDialog = true;
              this.selectedLegID = params.data.id;
              this.selectedManualSequence = params.data.manual_sequence;
              this.legManualSequenceDialogTitle = `Manual Sequence - ${params.data.leg_id}`;
            }
          },
          cellRenderer: function (params) {
            return `<span>${params.value ? params.value : ""}
                <i class="mdi mdi-pencil primary--text"></i>
              </span>`;
          },
        },
        {
          headerName: "Movement Date",
          field: "movement_date",
          sortable: true,
          checkboxSelection: true,
          minWidth: 100,
          cellClass: ["font-weight-bold"],
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Time",
          field: "movement_time",
          tooltipField: "movement_time",
          minWidth: 40,
        },
        {
          headerName: "ETA Time",
          field: "leg_eta_time",
          tooltipField: "leg_eta_time",
          sortable: true,
          minWidth: 50,
          onCellClicked: (params) => {
            if (this.containerCentric === "No") {
              this.showlegETADialog = true;
              this.selectedLegID = params.data.id;
              if (params.data.leg_eta_time) {
                this.selectedLegETA = params.data.leg_eta_time.replace(
                  " ",
                  "T"
                );
              } else {
                this.selectedLegETA = null;
              }
              this.legETADialogTitle = `ETA Time - ${params.data.leg_id}`;
            }
          },
          cellRenderer: function (params) {
            return `<span>${params.value ? params.value : ""}
                <i class="mdi mdi-pencil primary--text"></i>
              </span>`;
          },
        },
        {
          headerName: "Source",
          field: "source_location_code",
          sortable: true,
          minWidth: 50,
          tooltipField: "source_address",
        },
        {
          headerName: "Mid",
          field: "mid_location_code",
          sortable: true,
          minWidth: 50,
          tooltipField: "mid_address",
        },
        {
          headerName: "Dest",
          field: "destination_location_code",
          sortable: true,
          minWidth: 50,
          tooltipField: "destination_address",
        },

        {
          headerName: "Trip Date",
          field: "trip_date",
          sortable: true,
          minWidth: 70,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },
        {
          headerName: "Vehicle",
          field: "vehicle_number",
          tooltipField: "vehicle_number",
          minWidth: 65,
          valueFormatter: (params) => {
            if (params.data.is_sub_hire == "Yes") {
              return params.data.transporter_vehicle;
            } else {
              return params.value;
            }
          },
          sortable: true,
        },
        {
          headerName: "Driver",
          field: "driver_name",
          tooltipField: "driver_name",
          minWidth: 65,
          valueFormatter: (params) => {
            if (params.data.is_sub_hire == "Yes") {
              return params.data.transporter_driver;
            } else {
              return params.value;
            }
          },
          sortable: true,
        },
        {
          headerName: "Trailer 1",
          field: "trailer_1_number",
          tooltipField: "trailer_1_number",
          valueFormatter: (params) => {
            if (params.data.is_sub_hire == "Yes") {
              return params.data.transporter_trailer;
            } else {
              return params.value;
            }
          },
          sortable: true,
          minWidth: 65,
        },
        {
          headerName: "Trailer 2",
          field: "trailer_2_number",
          tooltipField: "trailer_2_number",
          minWidth: 65,
          sortable: true,
        },

        {
          headerName: "Container Type",
          field: "leg_container_details.types",
          tooltipField: "leg_container_details.types",
          minWidth: 100,
        },
        {
          headerName: "Count",
          field: "leg_container_details.count",
          minWidth: 30,
        },
        {
          headerName: "Container No(s)",
          field: "leg_container_details.numbers",
          tooltipField: "leg_container_details.numbers",
          minWidth: 100,
        },
        {
          headerName: "Bonus",
          field: "bonus",
          tooltipField: "bonus",
          minWidth: 100,
        },
        {
          headerName: "Weight",
          field: "leg_container_details.weights",
          tooltipField: "leg_container_details.weights",
          minWidth: 50,
        },
        {
          headerName: "Seal",
          field: "leg_container_details.seal_numbers",
          tooltipField: "leg_container_details.seal_numbers",

          onCellClicked: (params) => {
            if (this.containerCentric === "No") {
              this.showlegContainerSealNoDialog = true;
              this.selectedLegID = params.data.id;
              this.legContainerSealNoDialogTitle = `Container Seal No - ${params.data.leg_id}`;
            }
          },
          cellRenderer: function (params) {
            return `<span>${params.value ? params.value : ""}
                <i class="mdi mdi-pencil primary--text"></i>
              </span>`;
          },
        },

        {
          headerName: `Total Amount (${this.$globals.currency})`,
          field: "da_total_rate",
          minWidth: 100,
          tooltipField: "da_total_rate",
        },
        {
          headerName: `Sell Rate (${this.$globals.currency})`,
          field: "rate",
          minWidth: 55,
          tooltipField: "customer_contract_id",
        },

        {
          headerName: "Remarks",
          field: "da_remarks",
          tooltipField: "da_remarks",
          minWidth: 65,
          onCellClicked: (params) => {
            if (
              this.daPermissions &&
              this.daPermissions.add &&
              this.containerCentric === "No"
            ) {
              this.showDARemarksDialog = true;
              this.selectedDAID = params.data.da_id;
              this.selectedDARemarks = params.data.da_remarks;
              this.daRemarksDialog = `DA Remarks - ${params.data.da_no}`;
            }
          },
          cellRenderer: function (params) {
            return `<span>${params.value ? params.value : ""}
                <i class="mdi mdi-pencil primary--text"></i>
              </span>`;
          },
        },
        {
          headerName: "Company",
          field: "company_name",
          sortable: true,
          minWidth: 100,

          onCellClicked: (params) => {
            if (params.data.is_sub_hire == "Yes") {
              this.showSubhireDialog = true;
              this.selectedLegID = params.data.id;
              this.selectedRowData = params.data;
            }
          },
          tooltipField: "company_name",
          cellRenderer: function (params) {
            if (params.data.is_sub_hire == "Yes") {
              return `<span>${params.value ? params.value : ""}
                <i class="mdi mdi-pencil primary--text"></i>
              </span>`;
            } else {
              return params.value;
            }
          },
        },
        {
          headerName: "Sub Hire",
          field: "is_sub_hire",
          minWidth: 80,
          onCellClicked: (params) => {
            if (params.data.is_sub_hire == "Yes") {
              this.showSubhireDialog = true;
              this.selectedLegID = params.data.id;
              this.selectedRowData = params.data;
            }
          },
          cellRenderer: function (params) {
            if (params.data.is_sub_hire == "Yes") {
              return `<span>${params.value ? params.value : ""}
                <i class="mdi mdi-pencil primary--text"></i>
              </span>`;
            } else {
              return params.value;
            }
          },
        },
        {
          headerName: "Sub Hire ID",
          field: "sub_hire_no",
          minWidth: 100,
          cellClass: ["font-weight-bold"],
          checkboxSelection: (params) => {
            if (
              params &&
              params.data &&
              params.data.is_sub_hire == "Yes" &&
              params.data.leg_sub_hire
            ) {
              return true;
            }
          },
        },
        {
          headerName: "Job Type No",
          field: "job_type",
          tooltipField: "job_type",
          minWidth: 60,
          sortable: true,
        },

        {
          headerName: "Start Time",
          field: "leg_start_date_time",
          minWidth: 70,
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: "Finish Time",
          minWidth: 70,
          field: "leg_end_date_time",
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
          sortable: true,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value, true);
          },
        },
        {
          headerName: `DA Rate (${this.$globals.currency})`,
          field: "customer_rate",

          minWidth: 100,
          cellRenderer: (params) => {
            return params.data.is_sub_hire === "No" ? params.value : "";
          },
        },

        {
          headerName: `Buy Rate (${this.$globals.currency})`,
          field: "leg_rate",

          minWidth: 58,
          cellRenderer: (params) => {
            return params.data.is_sub_hire === "Yes" ? params.value : "";
          },
        },

        {
          headerName: "BOE",
          field: "boe",
          minWidth: 60,
          sortable: true,
          tooltipField: "boe",
        },

        {
          headerName: "Leg",
          field: "leg_id",
          minWidth: 75,
          cellClass: ["font-weight-bold"],
          tooltipField: "status",
          sortable: true,
        },
        {
          headerName: "Leg Type",
          field: "leg_type",
          minWidth: 75,
          tooltipField: "leg_type",
          sortable: true,
        },

        {
          headerName: "Trip",
          field: "trip_id",
          tooltipField: "trip_status",
          minWidth: 80,
          sortable: true,
          cellClass: ["primary--text font-weight-bold cursor-pointer"],
          cellStyle: (params) => {
            let style = {};

            return style;
          },

          onCellClicked: (params) => {
            if (
              this.permissions &&
              this.permissions.change &&
              params.data &&
              params.data.trip_pk
            ) {
              const path = {
                path: `/app/admin/trip/details/${params.data.trip_pk}`,
                query: {},
              };
              this.$router.push(path);
            }
          },
        },
        {
          headerName: "Branch",
          field: "branch",
          sortable: true,
          minWidth: 50,
          tooltipField: "branch",
        },
        {
          headerName: "Actions",
          field: "actions",
          pinned: "right",
          minWidth: 100,
          maxWidth: 120,
          cellRendererFramework: "AdminTripSchedulingButton",
        },
      ],
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return null;
      } else {
        return obj;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    nonEmptyFilters() {
      return Object.fromEntries(
        Object.entries(this.filters).filter(([key, value]) => {
          if (typeof value === "object" && value !== null) {
            return Object.keys(value).length > 0; // Only include non-empty objects
          }

          return value;
        })
      );
    },
  },

  watch: {
    tabs() {
      this.itemsPerPageChanged(100);
    },
    containerCentric(val) {
      this.gridColumnApi.setColumnVisible("actions", val === "No");
    },
  },
  methods: {
    onEnter() {
      this.pageNo = 1;
      this.getLegsList();
    },
    setDate(inputType) {
      if (
        inputType == "start" &&
        Date.parse(this.filters.start_date) > Date.parse(this.filters.end_date)
      ) {
        this.filters.end_date = this.filters.start_date;
      }

      setTimeout(() => {
        const field = this.$refs.endDateField;
        if (field) {
          field.setDefaultDateTime();
        }
      }, 500);

      if (
        inputType == "end" &&
        Date.parse(this.filters.start_date) > Date.parse(this.filters.end_date)
      ) {
        this.filters.start_date = this.filters.end_date;
      }

      setTimeout(() => {
        const field = this.$refs.startDateField;
        if (field) {
          field.setDefaultDateTime();
        }
      }, 500);
    },
    nextDate(inputType) {
      if (inputType == "start" && this.$refs.startDateField) {
        const startField = this.$refs.startDateField;
        if (startField) {
          startField.nextDate();
        }
      }
      if (inputType == "end" && this.$refs.endDateField) {
        const endField = this.$refs.endDateField;
        if (endField) {
          endField.nextDate();
        }
      }
    },
    getLists(apis) {
      this.$bus.$emit("showLoader", true);

      return new Promise((resolve, reject) => {
        Promise.all(apis)
          .then((val) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            resolve();
          })
          .catch((err) => {
            this.getListCalled = false;
            this.$bus.$emit("showLoader", false);
            reject();
          });
      });
    },
    previousDate(inputType) {
      if (inputType == "start") {
        if (this.$refs.startDateField) {
          const startField = this.$refs.startDateField;
          if (startField) {
            startField.previousDate();
          }
        }
      }
      if (inputType == "end") {
        if (this.$refs.endDateField) {
          const endField = this.$refs.endDateField;
          if (endField) {
            endField.previousDate();
          }
        }
      }
    },
    onSelectionChanged() {
      this.selectedLags = this.gridApi.getSelectedRows();
      this.movementDateCount = this.selectedLags.length;

      for (let i = 0; i < this.selectedLags.length; i++) {
        if (this.selectedLags[i].leg_sub_hire) {
          this.isSubHireId = true;
        } else {
          this.isSubHireId = false;
          return;
        }
      }
    },
    isRowSelectable(params) {
      return (
        this.containerCentric == "No" &&
        !!params.data &&
        params.data.status == "Created"
      );
    },
    allChecked() {
      if (this.all) {
        this.filters.status = [
          "Created",
          "Assigned",
          "Enroute",
          "Reached Drop Location",
          "Ready For Invoice",
          "Completed",
        ];
        this.itemsPerPageChanged(100);
      } else {
        this.filters.statuses = [];
        this.itemsPerPageChanged(100);
      }
    },
    resizeGrid() {
      if (this.gridApi) {
        setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
        }, 100);
      }
    },
    closeTripForm() {
      this.showAdminTripForm = false;
    },
    resetFilters() {
      const { start_date, end_date } = this.filters;    
      this.filters = {
        statuses: [],
        start_date,
        end_date,
      };
      this.itemsPerPageChanged(100);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    search() {
      this.movementDateCount = 0;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.pageNo = 1;
        this.getLegsList();
      }, 1000);
    },
    itemsPerPageChanged(e) {
      this.movementDateCount = 0;
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getLegsList();
    },
    prevPage() {
      this.movementDateCount = 0;
      this.pageNo--;
      this.getLegsList();
    },
    nextPage() {
      this.movementDateCount = 0;
      this.pageNo++;
      this.getLegsList();
    },
    applyGridSort(key, type) {
      this.itemsPerPageChanged(this.itemsPerPage);
    },
    getLegsList(params = {}) {
      if (this.legListTimeout) {
        clearTimeout(this.legListTimeout);
      }

      this.legListTimeout = setTimeout(() => {
        this.$bus.$emit("showLoader", true);
        params = {
          offset: this.offset,
          limit: this.itemsPerPage,
          ...params,
          ...this.filters,
        };

        delete params.sorting;

        if (this.sorting && Object.keys(this.sorting)) {
          let ordering = Object.keys(this.sorting).map((key) => {
            if (this.sorting[key] == true) {
              return key;
            }
            if (this.sorting[key] == false) {
              return `-${key}`;
            }
          });
          if (ordering && ordering.length) {
            params.ordering = ordering.join();
          }
        }

        if ("statuses" in params && params.statuses.length > 0) {
          params = { ...params, status: params.statuses.join(",") };
        }
        if ("transporters" in params && params.transporters.length > 0) {
          params = { ...params, companies: params.transporters.join(",") };
        }
        Object.keys(this.filters).forEach((key) => {
          if (Array.isArray(this.filters[key])) {
            params[key] = this.filters[key].join(",");
          } else {
            params[key] = this.filters[key];
          }
        });

        let function_name = "getLegList";
        if (params && params.containerCentric == "Yes") {
          this.containerCentric = "Yes";
        } else {
          this.containerCentric = "No";
        }
        delete params.statuses;
        delete params.transporters;
        delete params.containerCentric;

        if (this.containerCentric === "Yes") {
          function_name = "getLegContainerList";
        }

        this.setFilters();
        this.isSubHireId = false;
        this.selectedLags = [];
        this.$api.trip[function_name](params)
          .then((res) => {
            this.allLegList = res.data;
            this.totalLegs = res.count;
            this.$bus.$emit("showLoader", false);
            this.movementDateCount = 0;
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }, 100);
    },

    viewTrip(id) {
      this.$router.push({
        path: `/app/admin/trip/details/${id}`,
        query: {},
      });
    },
    combineLegs(legId, transporterCompanyId) {
      this.selectedLegId = legId;
      this.transporterCompanyId = transporterCompanyId;
      this.showCombineLegForm = true;
    },
    deAllocateTransporter(leg) {
      if (confirm("Are you sure you want to un-allocate transporter?")) {
        this.$api.trip
          .deAllocateTransporter(leg.id)
          .then(() => {
            this.$bus.$emit("showLoader", false);
            this.getLegsList();
            this.movementDateCount = 0;
            this.$bus.$emit("showToastMessage", {
              message: "Transporter Unallocated Successfully!",
              color: "success",
            });
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't udpate transporter!",
              color: "error",
            });
          });
      }
    },
    allocateAsset(leg) {
      this.selectedLegId = leg.id;
      this.showAdminTripForm = true;
    },

    checkQuery() {
      if (this.$route.query && this.$route.query.active_tab) {
        this.tabs = parseInt(this.$route.query.active_tab);
      }
    },
    checkFilters() {
      const filters = localStorage.getItem("new_trip_scheduling_filters");
      const user_data = JSON.parse(localStorage.getItem("userProfile"));

      if (filters) {
        this.filters = JSON.parse(filters);
        if (this.filters.sorting) {
          this.sorting = { ...this.filters.sorting };
          this.headers.map((obj) => {
            if (obj.field in this.sorting) {
              if (this.sorting[obj.field] == false) {
                obj.sort = "desc";
              } else if (this.sorting[obj.field]) {
                obj.sort = "asc";
              }
            }
          });
        }
      } else {
        this.filters.start_date = new Date().toISOString().slice(0, 10);
        this.filters.end_date = new Date().toISOString().slice(0, 10);
      }
      if (user_data && user_data.depot) {
        this.filters.depot = user_data.depot;
      }
      if (user_data && user_data.branch) {
        this.filters.branch = user_data.branch;
      }
    },
    setFilters() {
      if (
        this.filters &&
        Object.keys(this.filters).length > 0 &&
        Object.keys(this.filters).indexOf("ordering")
      ) {
        delete this.filters.ordering;
      }
      localStorage.setItem(
        "new_trip_scheduling_filters",
        JSON.stringify({ ...this.filters, sorting: this.sorting })
      );
    },

    resetForm() {
      this.selectedLags = [];
    },
    completeLegs() {
      const config = {
        status: "secondary",
        title: "Sub Hire Job DAs",
        text: "Are you sure you want to complete the DAs?",
      };

      this.$confirm(config).then(() => {
        this.$bus.$emit("showLoader", true);
        this.formErrors = {};
        let params = {
          legs: this.selectedLags.map((leg) => leg.id),
        };

        this.$api.deliveryAdvice
          .completeSubHireLeg(params)
          .then(() => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Delivery Advices updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.getLegsList();
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't update delivery advices!",
              color: "error",
            });
            if (err && err.data) {
              this.formErrors = err.data;
            }
          });
      });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("leg_trip");
      this.daPermissions = getPermissions("delivery_advice");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        if (!this.getListCalled) {
          this.getListCalled = true;
          this.checkFilters();
          this.getLists([this.checkQuery(), this.getLegsList()]);
        }
      }
    }
  },
};
</script>
